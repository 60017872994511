import Web3 from 'web3';
const contractABI = require("../contracts/ShadesOfYou.json");
const contractAddress = "0xA3240c269aD0eBEF13647C726E532E964C55EF3d" 
const price = 50; //0.05 ETH
let web3 = undefined
// let account = undefined
let contract = undefined
// Requires Infura or Alchemy API key
const web3Alchemy = new Web3('wss://mainnet.infura.io/ws/v3/77b1a03f653644c0abd36f7471d8293a')
let subscription = undefined;

export function isWeb3Ready() {
    return web3 !== undefined
}

export async function mintSOY(provider, isMobile, web3, total, accountFrom, onHash) {
  const contract = new web3.eth.Contract(contractABI.abi, contractAddress);
  const tx = {
    maxPriorityFeePerGas: null,
    maxFeePerGas: null, 
    // this could be provider.addresses[0] if it exists
    from: accountFrom, 
    // target address, this could be a smart contract address
    to: contractAddress, 
    // optional if you want to specify the gas limit 
    // gas: gasLimit, 
    // number of NFT * price per each
    value: web3.utils.toWei(`${price * total}`, 'finney'),
    // this encodes the ABI of the method and the arguments
    // contract.methods.mintAllowList(total, ['0x26c0de9f5897dee17d316c0ea5a6ec852cff5b477ea37d3338d235c42b72b251', '0x72dd0ecc29d05c5291f1d3c64f5650da6a2dbe34d3da85f9ae2e9bccf8b21f64']).encodeABI() 
    data: contract.methods.mint(total).encodeABI() 
  };
  if (isMobile) {
    try {
      const transactionHash = await provider.request({
      method: 'eth_sendTransaction',
      params: [
        tx
      ],
    });
    onHash(transactionHash)
  } catch (e) {
  }
} else {
  web3.eth.sendTransaction(tx).on('transactionHash', onHash).on('error', function(error){  })

}
}

export async function mintPresaleSOY(provider, isMobile, web3, total, accountFrom, hash, onHash) {
  const contract = new web3.eth.Contract(contractABI.abi, contractAddress);
  const tx = {
    maxPriorityFeePerGas: null,
    maxFeePerGas: null, 
    // this could be provider.addresses[0] if it exists
    from: accountFrom, 
    // target address, this could be a smart contract address
    to: contractAddress, 
    // optional if you want to specify the gas limit 
    // gas: gasLimit, 
    // number of NFT * price per each
    value: web3.utils.toWei(`${price * total}`, 'finney'),
    // this encodes the ABI of the method and the arguments
    data: contract.methods.mintAllowList(total, hash).encodeABI() 
  };
  if (isMobile) {
      try {
        const transactionHash = await provider.request({
        method: 'eth_sendTransaction',
        params: [
          tx
        ],
      });
      onHash(transactionHash)
    } catch (e) {
    }
  } else {
    web3.eth.sendTransaction(tx).on('transactionHash', onHash).on('error', function(error){  })

  }

}


export async function getTotalSupply(web3) {
  contract = new web3.eth.Contract(contractABI.abi, contractAddress)
  return contract.methods.totalSupply().call();
}

export async function getBalance(address) {
  const balance = await contract.methods.balanceOf(address).call();
  return balance;
}

export async function subscribeMintEvent(callback) {
  contract = new web3Alchemy.eth.Contract(contractABI.abi, contractAddress)
  const eventJsonInterface = web3Alchemy.utils._.find(
    contract._jsonInterface,
    o => o.name === 'Transfer' && o.type === 'event',
  )
  subscription = web3Alchemy.eth.subscribe('logs', {
      address: contract.options.address,
      topics: [eventJsonInterface.signature]
    }, (error, result) => {
      if (!error && web3Alchemy) {
        const eventObj = web3Alchemy.eth.abi.decodeLog(
          eventJsonInterface.inputs,
          result.data,
          result.topics.slice(1)
        )
        console.log(eventObj)
        callback(eventObj && eventObj.tokenId)
      }
    })
}

export async function getTokens(web3, address) {
  contract = new web3Alchemy.eth.Contract(contractABI.abi, contractAddress)
  const balance = await contract.methods.balanceOf(address).call();
  if (balance === 0) {
    return [];
  }
  const tokens = await Promise.all(Array.from({length: balance - 1},(v,k)=>k+1).map(el => contract.methods.tokenOfOwnerByIndex(address, el).call()));
  console.log(tokens);
  return tokens;
}
