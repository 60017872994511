import './App.css'
import crossBig from './assets/cross-big.svg'
import trusted from './assets/trusted/trusted.webp'
import openLink from './assets/open-link-w.svg'
import metamask from './assets/metamask.svg'
import walletConnect from './assets/walletc.svg'
import Web3 from 'web3'
import detectEthereumProvider from '@metamask/detect-provider';
import { mintSOY, mintPresaleSOY, getTotalSupply, subscribeMintEvent, getBalance } from "./model/Blockchain"
import { useEffect, useState, useCallback } from 'react'
import { Navbar, Container, Row, Col, Button, Alert, Form, InputGroup, Modal} from 'react-bootstrap'
import TrustedModal from './components/TrustedModal'
import WalletConnectProvider from "@walletconnect/web3-provider";
import logo from './assets/logo.png'
import soy from './assets/soy.gif'
import traits from './assets/about_traits.png'
import rarity from './assets/rarity.png'
import coinbaseWallet from "./assets/coibaseWallet.webp";
import WL from "./model/WL";
import WalletLink from "walletlink";
import Countdown from 'react-countdown';

const MAX_PRESALE = 5;

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = useState(
    localStorage.getItem(localStorageKey) || 0
  );
 
  useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);
 
  return [value, setValue];
};
const MINT_COUNT = 20;

function App() {
  const [mintedByMe, setMintedByMe] = useStateWithLocalStorage(
    'c5dd0eff-4779-4875-98fc-1016115ecb2a'
  );
  const [ethBalance, setEthBalance] = useState('Ξ')
  const [currentAccount, setCurrentAccount] = useState('')
  const [isLogged, setIsLogged] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [modalTrustedShow, setModalTrustedShow] = useState(false);
  const [modalConnectShow, setModalConnectShow] = useState(false);
  const [minted, setMinted] = useState(0);
  const [balance, setBalance] = useState(0);
  const [isWL, setIsWL] = useState(false);
  const [saleState, setSaleState] = useState('presale');

  const [playing, setPlaying] = useState(false);
  const [_web3, setWeb3] = useState();
  const [currentProvider, setProvider] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  let isMobile = width <= 768;
  async function getEthBalance(walletAddress) {
      try {
        const web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/2c9f24ade5554e4a84e0c8cd4c8c1877'));
        var balance = await web3.eth.getBalance(walletAddress);
        console.log(web3.utils.fromWei(balance, "ether"));
        // balance = web3.toDecimal(balance);
        setEthBalance(`${parseFloat(web3.utils.fromWei(balance, "ether")).toFixed(3)} Ξ`);
      } catch {}
  }

  const SignIn = useCallback(async () => {
    setModalConnectShow(true)
  }, [])

  // const connectEth = async (provider, isMetamask) => {
  //   console.log(provider)
  //    const web3 = new Web3(provider)
  //    setWeb3(web3)
  //    if(!provider) {
  //      setMessage([{head : "Wallet not found", body: `Please install the MetaMask!`, variant: 'warning'}])
  //    } else {
  //      const address = await ConnectWallet(web3, isMetamask);
  //      if (!!address)
  //        setMessage(messages =>[...messages.filter(el => el.body !== `Address: ${address}`), {head : "👋 You are logged in", body: `Address: ${address}`, variant: 'success'}])
  //        const [supply] = await Promise.all([getTotalSupply(web3), getEthBalance(address)]);
  //        setMinted(supply.toString());
  //        setWeb3(web3)
  //    }
  // };

  // const connectMetamask = async () => {
  //   console.log('Connecting metamask')

  //   setModalConnectShow(false)
  //   const provider = await detectEthereumProvider();
  //   setProvider(provider);
  //   await connectEth(provider, true);
  // };

  const connectWalletC = async () => {
    console.log('Connecting wallet')
    setModalConnectShow(false)
    const provider = new WalletConnectProvider({
      infuraId: "2c9f24ade5554e4a84e0c8cd4c8c1877",
    });
    try {
      await provider.enable();
      setProvider(provider);
      await connectEth(provider, false);
    } catch(e) {
      console.log(e)
    }
  };

  // const ConnectWallet = async (web3, isMetamask) => {
  //   try {
  //     const accounts = isMetamask ? await window.ethereum.request({ method: 'eth_requestAccounts' }) : await web3.eth.getAccounts(); 
  //     setIsLogged(true)
  //     setCurrentAccount(accounts[0])
  //     return accounts[0]

  //   } catch(err) {
  //       if (err.code === 4001) {
  //         // EIP-1193 userRejectedRequest error
  //         // If this happens, the user rejected the connection request.
  //         console.log('Please connect to MetaMask.')
  //         setMessage(messages =>[...messages, {head : "User Rejected Request", body: 'Please connect to a MetaMask.', variant: 'info'}])

  //       } else if(err.code === -32002) {
  //         console.log('Please unlock MetaMask.')
  //         setMessage(messages =>[...messages, {head : "User Request Pending", body: 'Please unlock a MetaMask and try agin.', variant: 'info'}])
  //       } else {
  //         console.error(err);
  //         setMessage(messages =>[...messages, {head : "Error", body: err.message, variant: 'info'}])
  //       }
  //   }
  // }

  const handleAccountsChanged = async (accounts) => {
    //if(!isLogged) return
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      setMessage(messages => [...messages, {head : "User Rejected Request", body: 'Please connect to MetaMask.', variant: 'info'}])
    } else if (accounts[0] !== currentAccount) {
      setCurrentAccount(accounts[0])
      await getEthBalance(accounts[0])
      // setMessage(messages => [...messages, {head : "👋 You are logged in", body: `Address: ${accounts[0]}`, variant: 'warning'}])
    }
  }

  useEffect(() => {
    const now = new Date().getTime();
      if (now < 1645293600000) {
      setSaleState('presale')
    } else {
      setSaleState('sale')
    }
    window.onbeforeunload = function() { return "Prevent reload" }
    const newImage = new Image();
    newImage.src = './assets/bg.jpeg';
    window['./assets/bg.jpeg'] = newImage;
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }
    subscribeMintEvent((id) => {
      setMinted(`${parseInt(id) + 1}`.toString());
    })
    // window.ethereum.on('chainChanged', (_chainId) => {
    //   console.log(_chainId);
    //   setCurrentChainID(() => parseInt(_chainId, 16))
    //   //window.location.reload()
    // });
  }, []);

  const SignOut = async () => {
    setIsLogged(false)
    setCurrentAccount('');
    setEthBalance('Ξ');
    try { await currentProvider.disconnect() } catch {}
  }

  const shortAddr = () => {
    return `${currentAccount.substr(0,4)}...${currentAccount.substring(currentAccount.length - 4, currentAccount.length)}`
  }
  const [messages, setMessage] = useState([])

  const Message = (props) => {
    const [show, setShow] = useState(true);

    const close = () => {
      setShow(false)
      setMessage(messages.filter((item, index) => index !== props.id))
    }

    useEffect(() => {
      // when the component is mounted, the alert is displayed for 3 seconds
      // if (!props.nohide) {
      //   setTimeout(() => {
      //     close();
      //   }, 3000);
      // }
    }, []);  

    if(show) {
      return (
        <Alert className="flowty-alert" onClose={close} dismissible>
          <Alert.Heading>{props.head}</Alert.Heading>
          <p className="alert-flowty">
            {props.body} <a target="_blank" rel="noreferrer" href={props.url} role="button" tabIndex="0" className="alert-link-soy">{props.urlText1} <img alt="" hidden={!!!props.urlText1} style={{transition: "none"}} src={openLink} className="external-link"/></a>
          </p>
          <hr hidden={!!!props.info1} />
          <p hidden={!!!props.info1} className="mb-0 flowty-alert-sub">
            {props.info1} <a target="_blank" rel="noreferrer" href={props.url2} role="button" tabIndex="0" className="alert-link-soy">{props.urlText2} <img alt="" style={{transition: "none"}} src={openLink} className="external-link"/></a>
          </p>
          <p hidden={!!!props.info2} className="mb-0 flowty-alert-sub">
            {props.info2}
          </p>
        </Alert>
      )
    } else {
      return(<></>)
    }

    
  }

  // function ConectModal(props) {
  //   const {connectMetamask, connectWalletC} = props;
  //   return (
  //     <Modal
  //       {...props}
  //       className="align-items-center justify-content-center"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       dialogClassName="trusted-modal"
  //       centered
  //     >
  //       <Modal.Body >
  //         <Row className="align-items-center justify-content-center h-100 row-border wallet-connect-hover" onClick={connectMetamask}>
  //           <Col className="align-items-center justify-content-center ">
  //             <Row className="align-items-center justify-content-center mt-3">
  //               <img alt="" style={{transition: "none"}} src={metamask} className="metamask-icon"/>
  //             </Row>
  //             <Row className="align-items-center justify-content-center mt-2">
  //               <div className="wallet-connect-title text-center">MetaMask</div>
  //             </Row>
  //             <Row className="align-items-center justify-content-center mb-5">
  //               <div className="wallet-connect-sub text-center">Connect to your MetaMask Wallet</div>
  //             </Row>
  //           </Col>
  //         </Row>
  //         <Row className="align-items-center justify-content-center h-100 wallet-connect-hover" onClick={connectWalletC} >
  //           <Col className="align-items-center justify-content-center ">
  //             <Row className="align-items-center justify-content-center mt-3">
  //               <img alt="" style={{transition: "none"}} src={walletConnect} className="metamask-icon"/>
  //             </Row>
  //             <Row className="align-items-center justify-content-center mt-2">
  //               <div className="wallet-connect-title text-center">Wallet Connect</div>
  //             </Row>
  //             <Row className="align-items-center justify-content-center mb-5">
  //                 <div className="wallet-connect-sub text-center">Scan with WalletConnect to connect</div>
  //             </Row>
  //           </Col>
  //         </Row>
  //       </Modal.Body>
  //     </Modal>
  //   );
  // }

  const connectEth = async (provider, isMetamask) => {
    try {
      const web3 = new Web3(provider);
      if (!provider) {
        setMessage([
          {
            head: "Wallet not found",
            body: `Please install the MetaMask!`,
            variant: "warning",
          },
        ]);
      } else {
        const address = await ConnectWallet(web3, isMetamask);
        if (!!address) {
          setMessage((messages) => [
            ...messages.filter((el) => el.body !== `Address: ${address}`),
            {
              head: "👋 You are logged in",
              body: `Address: ${address}`,
              variant: "success",
            },
          ]);
          setCurrentAccount(address);
          const isWL = WL.filter(el => el[0].toLowerCase() === address.toLowerCase()).length > 0;
          console.log(`is WL ${isWL}`)
          setIsWL(isWL);
          const [supply, balance] = await Promise.all([getTotalSupply(web3), getBalance(address), getEthBalance(address)]);
          setBalance(balance);
          setMinted(supply.toString());
          setWeb3(web3)
          window.ethereum.on("accountsChanged", handleAccountsChanged);
        }
      }
    } catch (err) {
      // Sentry.captureException(err);
    }
  };

  const connectMetamask = async () => {
    setModalConnectShow(false);
    const provider = await detectEthereumProvider();
    setProvider(provider);
    await connectEth(provider, true);
  };

  const connectWalletConnect = async () => {
    setModalConnectShow(false);
    const provider = new WalletConnectProvider({
      infuraId: "2c9f24ade5554e4a84e0c8cd4c8c1877",
    });
    try {
      await provider.enable();
      setProvider(provider);
      await connectEth(provider, false);
    } catch (e) {
      console.error(e);
    }
  };

  const connectWalletLink = async () => {
    setModalConnectShow(false);
    const walletLink = new WalletLink({
      appName: "Flowtys Studio",
      appLogoUrl: "/favicon.ico",
      darkMode: true,
    });

    // Initialize a Web3 Provider object
    const provider = walletLink.makeWeb3Provider(
      `https://mainnet.infura.io/v3/2c9f24ade5554e4a84e0c8cd4c8c1877`,
      1
    );

    try {
      setProvider(provider);
      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });
      setIsLogged(true);
      setCurrentAccount(accounts[0]);
      const web3 = new Web3(provider);
      const [supply, balance] = await Promise.all([getTotalSupply(web3), getBalance(accounts[0]), getEthBalance(accounts[0])]);
      setBalance(balance);
      setMinted(supply.toString());
      setWeb3(web3)
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    } catch (e) {
      console.error(e);
    }
  };

  const ConnectWallet = async (web3, isMetamask) => {
    try {
      const accounts = isMetamask
        ? await window.ethereum.request({ method: "eth_requestAccounts" })
        : await web3.eth.getAccounts();
      setIsLogged(true);
      setCurrentAccount(accounts[0]);
      return accounts[0];
    } catch (err) {
      if (err.code === 4001) {
        // EIP-1193 userRejectedRequest error
        // If this happens, the user rejected the connection request.
        console.info("Please connect to MetaMask.");
        setMessage((messages) => [
          ...messages,
          {
            head: "User Rejected Request",
            body: "Please connect to a MetaMask.",
            variant: "info",
          },
        ]);
      } else if (err.code === -32002) {
        console.info("Please unlock MetaMask.");
        setMessage((messages) => [
          ...messages,
          {
            head: "User Request Pending",
            body: "Please unlock a MetaMask and try agin.",
            variant: "info",
          },
        ]);
      } else {
        console.error(err);
        setMessage((messages) => [
          ...messages,
          { head: "Error", body: err.message, variant: "info" },
        ]);
      }
    }
  };
  function ConectModal(props) {
    return (
      <Modal
        {...props}
        className="align-items-center justify-content-center"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="trusted-modal"
        centered
      >
        <Modal.Body>
          <Row
            hidden={isMobile}
            className="align-items-center justify-content-center h-100 row-border wallet-connect-hover"
            onClick={connectMetamask}
          >
            <Col className="align-items-center justify-content-center ">
              <Row className="align-items-center justify-content-center mt-3">
                <img
                  alt=""
                  style={{ transition: "none" }}
                  src={metamask}
                  className="metamask-icon"
                />
              </Row>
              <Row className="align-items-center justify-content-center mt-2">
                <div className="wallet-connect-title text-center">MetaMask</div>
              </Row>
              <Row className="align-items-center justify-content-center mb-5">
                <div className="wallet-connect-sub text-center">
                  Connect to your MetaMask Wallet
                </div>
              </Row>
            </Col>
          </Row>
          <Row
            className="align-items-center justify-content-center h-100 wallet-connect-hover row-border"
            onClick={connectWalletConnect}
          >
            <Col className="align-items-center justify-content-center ">
              <Row className="align-items-center justify-content-center mt-3">
                <img
                  alt=""
                  style={{ transition: "none" }}
                  src={walletConnect}
                  className="metamask-icon"
                />
              </Row>
              <Row className="align-items-center justify-content-center mt-2">
                <div className="wallet-connect-title text-center">
                  Wallet Connect
                </div>
              </Row>
              <Row className="align-items-center justify-content-center mb-5">
                <div className="wallet-connect-sub text-center">
                  Connect your wallet ( MetaMast & more)
                </div>
              </Row>
            </Col>
          </Row>
          <Row
            className="align-items-center justify-content-center h-100 wallet-connect-hover"
            onClick={connectWalletLink}
          >
            <Col className="align-items-center justify-content-center ">
              <Row className="align-items-center justify-content-center mt-3">
                <img
                  alt=""
                  style={{ transition: "none" }}
                  src={coinbaseWallet}
                  className="metamask-icon"
                />
              </Row>
              <Row className="align-items-center justify-content-center mt-2">
                <div className="wallet-connect-title text-center">
                  Coinbase Wallet
                </div>
              </Row>
              <Row className="align-items-center justify-content-center mb-5">
                <div className="wallet-connect-sub text-center">
                  Connect your browser or mobile app
                </div>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  const [amount, setAmount] = useState(1)
  const [validated, setValidated] = useState(false);

  function onInputchange(event) {
    setAmount(event.target.value)
  }

  const mintPressed = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false || !amount || amount > 10 || amount < 1) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return
    }
    event.preventDefault();
    setValidated(true);
    // const provider = await detectEthereumProvider()
    const web3 = new Web3(currentProvider);

    if (saleState === 'presale') {
      const hashW = WL.filter(el => el[0].toLowerCase() === currentAccount)[0][1]
      await mintPresaleSOY(currentProvider, isMobile, web3, amount, currentAccount, hashW, (hash) => {
        console.log(`Balance: ${balance + parseInt(amount)}`)
  
  
        setBalance(parseInt(balance) + parseInt(amount))
        setMessage(messages =>[...messages, {
          head : "🎉 ShadesOfYou on the way!",
          url: "https://etherscan.io/tx/" + hash,
          url2: "https://opensea.io/account",
          urlText1: "Etherscan",
          urlText2: "Opensea Account",
          body: `You can check the transaction on`,
          info1: 'Your NFT will then appear in your',
          info2: 'This might take a few minutes depending on network load.',
          nohide: true,
          variant: 'dark'
        }])
      });
    } else {
      await mintSOY(currentProvider, isMobile, web3, amount, currentAccount, (hash) => {
        console.log(`Balance: ${balance + parseInt(amount)}`)
  
  
        setBalance(parseInt(balance) + parseInt(amount))
        setMessage(messages =>[...messages, {
          head : "🎉 ShadesOfYou on the way!",
          url: "https://etherscan.io/tx/" + hash,
          url2: "https://opensea.io/account",
          urlText1: "Etherscan",
          urlText2: "Opensea Account",
          body: `You can check the transaction on`,
          info1: 'Your NFT will then appear in your',
          info2: 'This might take a few minutes depending on network load.',
          nohide: true,
          variant: 'dark'
        }])
      });
    }
 
   
    
  };
  
  return (
  <>

  <Navbar className="navbar-expand-md" variant="dark">
    <Navbar.Brand href="#home" className="mx-auto order-0">
      <img
        alt=""
        src={logo}
        width="330"
        height="90"
        className="d-inline-block align-top top-logo"
      />{' '}
      
      
    </Navbar.Brand>

  </Navbar>

    <div className="message-list mt-5" hidden={isMobile}>
        {
          messages.map((item,i) => (
            <Message head={item.head} 
            body={item.body} 
            variant={item.variant} id={i} key={i} url={item.url} url2={item.url2} 
            urlText1={item.urlText1}
            urlText2={item.urlText2} 
            info1={item.info1}
            info2={item.info2} 
            nohide={item.nohide} />
          ))
        }
    </div>
    <ConectModal
      show={modalConnectShow}
      connectMetamask={connectMetamask}
      connectWalletC={connectWalletC}
      onHide={() => setModalConnectShow(false)}
    />
      <TrustedModal  
        currentAccount={currentAccount}
        web3={_web3}
        show={modalTrustedShow}
        onHide={() => setModalTrustedShow(false)}/>
    <Container>   
      <Row className="align-items-center justify-content-center mb-5">
        <Col sm={3} className="text-left">
        <Row>          
            <span className="top-text-name txt-shadow mb-1">Shades Of You</span>
          </Row>
          <Row>          
            <span className="top-text txt-shadow mb-5">artistic view of your inner world</span>
          </Row>
          <Row className="justify-content-center text-bottom">
            <div className="top-text txt-shadow"> <div className="align-bottom"> Welcome to “Shades Of You”. We are bringing forth a collection of hand-illustrated digital art. 7000 masterpieces on Ethereum.</div></div>
          </Row>

        </Col>
        <Col sm={2} className="text-center">
          <img alt="" style={{transition: "none"}} src={soy} className="soy-gif"/>
        </Col>
      </Row>
      <Row  className="align-items-center justify-content-center h-100 mb-5">
        <Row className="justify-content-center text-bottom text-center">
            <div className="top-text txt-shadow"> <div className="align-bottom"> Smart Contract: <a target="_blank" rel="noreferrer" href="https://etherscan.io/address/0xa3240c269ad0ebef13647c726e532e964c55ef3d" role="button" tabIndex="0" className="alert-link-contract"> 0xA3240c269aD0eBEF13647C726E532E964C55EF3d <img alt="" style={{transition: "none"}} src={openLink} className="external-link"/></a>
 </div></div>
          </Row>
      </Row>


      <Row className="align-items-center justify-content-center h-100 mb-1">
        <div className="text-center minted-text txt-shadow"> <div className="align-bottom"> Minted: 7000/ 7000 </div></div>
      </Row>
      <Row  className="align-items-center justify-content-center h-100 mb-5">
        {/* <Col className="align-items-center justify-content-center"> */}
          <Row  md="auto" className="justify-content-center w-100"> 
            <Button className="main-button" target="_blank" rel="noreferrer" href="https://opensea.io/collection/soynft">Check OpenSea</Button>{' '}
          </Row>
        {/* </Col> */}
      </Row>
      <Row hidden={isLogged && saleState === 'presale' ? (isWL ? balance >= MAX_PRESALE : true) : true } className="align-items-center justify-content-center h-100 mb-5 mt-2">
        <Col className="align-items-center justify-content-center ">
          <Form noValidate validated={validated} onSubmit={mintPressed} className="">
            <Row className="justify-content-center w-100">
                <InputGroup className="mb-3 sale-input-box" size="lg">
                <Button className="sale-add" onClick={(e) => { e.preventDefault(); setAmount(Math.max(1, amount - 1)); }} >-</Button>

                  <Form.Control
                    disabled className="sale-input" type="number" value={amount} onChange={onInputchange} isInvalid={amount > MAX_PRESALE || amount < 1}
                  />
                  {/* <input type="text" formControlName="xyz" [readonly]="anyBooleanPropertyFromComponent" /> */}
                  <Button className="sale-add" onClick={(e) => { e.preventDefault(); setAmount(Math.min(amount + 1, MAX_PRESALE - balance)); }} >+</Button>
                  <Button className="sale-max" onClick={(e) => { e.preventDefault(); setAmount(MAX_PRESALE - balance); }} >MAX</Button>
                  <Form.Control.Feedback type="invalid">
                    Maximum 5 NFT per wallet.
                  </Form.Control.Feedback>
                </InputGroup>
            </Row>
            <Row md="auto" className="justify-content-center w-100" style={{transition: "none"}}> 
              <Col sm={4}>
                <Row className="justify-content-center w-100">                     
                  <Button disabled={isNaN(amount) || amount <= 0 || amount > MINT_COUNT} className="mint-button" type="submit" >{(isNaN(amount) || amount <= 0 || amount > MINT_COUNT ? 'Set Amount' :  `Mint ${amount} NFT for ${(5 * amount) / 100} Ξ`) }</Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row hidden={isLogged ? (saleState !== 'sale' || minted === '7000' ) : true } className="align-items-center justify-content-center h-100 mb-5 mt-2">
        <Col className="align-items-center justify-content-center ">
          <Form noValidate validated={validated} onSubmit={mintPressed} className="">
            <Row className="justify-content-center w-100">
                <InputGroup className="mb-3 sale-input-box" size="lg">
                <Button className="sale-add" onClick={(e) => { e.preventDefault(); setAmount(Math.max(1, amount - 1)); }} >-</Button>

                  <Form.Control
                    disabled className="sale-input" type="number" value={amount} onChange={onInputchange} isInvalid={amount > MINT_COUNT || amount < 1}
                  />
                  {/* <input type="text" formControlName="xyz" [readonly]="anyBooleanPropertyFromComponent" /> */}
                  <Button className="sale-add" onClick={(e) => { e.preventDefault(); setAmount(Math.min(amount + 1, MINT_COUNT)); }} >+</Button>
                  <Button className="sale-max" onClick={(e) => { e.preventDefault(); setAmount(MINT_COUNT); }} >MAX</Button>
                  <Form.Control.Feedback type="invalid">
                    Maximum 20 NFT per wallet.
                  </Form.Control.Feedback>
                </InputGroup>
            </Row>
            <Row md="auto" className="justify-content-center w-100" style={{transition: "none"}}> 
              <Col sm={4}>
                <Row className="justify-content-center w-100">                     
                  <Button disabled={isNaN(amount) || amount <= 0 || amount > MINT_COUNT} className="mint-button" type="submit" >{(isNaN(amount) || amount <= 0 || amount > MINT_COUNT ? 'Set Amount' :  `Mint ${amount} NFT for ${(5 * amount) / 100} Ξ`) }</Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center h-100 about-traits">
        <Row className="align-items-center justify-content-center mb-5">
          <Col sm={3} className="text-left mt-5">
          <Row>          
              <span className="traits-text-name txt-shadow mb-5">Who are you today?</span>
            </Row>
            <Row>          
              <span className="top-text txt-shadow mb-5">"Shades Of You" is a woman founded collection of 7k randomly generated NFTs on the Ethereum blockchain. This is a life work of a professional illustrator expressed as PFP collection, an artistic view of your inner world. Our goal is to build the biggest group of art connoisseurs and bring them into the Metaverse. For an accessible price of 0.05 ETH you receive 1 SOY NFT that provides you not only a masterpiece PFP, unique and nothing alike, but also lifelong friendship with 7k others friends and access to all future masterworks. </span>
            </Row>
          </Col>
          <Col sm={2} className="text-center">
            <img alt="" style={{transition: "none"}} src={traits} className="about-traits-img"/>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center mb-5">
          <img alt="" style={{transition: "none"}} src={rarity} className="about-rarity-img"/>
        </Row>
      </Row>
    </Container>
  </>
  );
}

export default App;
