import React from 'react'
import {  Row, Col, Modal } from 'react-bootstrap'
import trusted1 from '../assets/trusted1.webp'
import trusted2 from '../assets/trusted2.webp'
import trusted3 from '../assets/trusted3.webp'

class TrustedModal extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
          showHide : false
      }
    }

    handleModalShowHide() {
        this.setState({ showHide: !this.state.showHide })
    }

    render(){
        return(
            <div>
                <Modal
                  {... this.props}
                  size="lg"
                  className="align-items-center justify-content-center"
                  aria-labelledby="contained-modal-title-vcenter"
                  dialogClassName="trusted-modal trusted-modal-content  modal-90h"
                  centered
                >
                  <Modal.Header closeButton>
                    {/* <Modal.Title id="example-custom-modal-styling-title text-center">
                    Don’t get scammed !
                    </Modal.Title> */}
                  </Modal.Header>
                  <Modal.Body>
                   <Row>
                    <div className="trusted-modal-title">Don’t get scammed !</div>
                   </Row>
                   <Row>
                     <div className="trusted-test"><span className="bold-trusted">#1</span> source of a trusted information is our official web-page. Make sure the address is <span className="bold-trusted"><a rel="noreferrer" href="https://flowtys.com" target="_blank" role="button" tabIndex="0" className="alert-link-flowty">flowtys.com</a></span></div>
                   </Row>
                   <Row>
                    <img alt="" style={{transition: "none"}} src={trusted1} className="" />
                   </Row>
                   <Row className="mt-2 mb-5">
                     <Col className="trusted-col-text"> 
                        <Row>
                          <div className="trusted-test trusted-col-text"><span className="bold-trusted">#2</span> trust only an official <span className="bold-trusted"><a rel="noreferrer" href="https://opensea.io/collection/flowtys" target="_blank" role="button" tabIndex="0" className="alert-link-flowty">Flowtys Collection</a> </span> on <span className="bold-trusted"><a rel="noreferrer" href="https://opensea.io/collection/flowtys" target="_blank" role="button" tabIndex="0" className="alert-link-flowty">OpenSea</a></span>. Check the name and a contract address</div>
                        </Row>
                        <Row className="mt-2 align-items-center justify-content-center">
                          <img alt="" style={{transition: "none"}} src={trusted2} className="" />
                        </Row>
                     </Col>
                     <Col className="trusted-col2-text">
                        <Row>
                          <div className="trusted-test trusted-test2 pr-5"><span className="bold-trusted">#3</span> always double check the address of a website that is triggering transaction and a destination address</div>
                        </Row>
                        <Row className="trusted-img2 align-items-center justify-content-center">
                          <img alt="" style={{transition: "none"}} src={trusted3} className="" />
                        </Row>
                     </Col>
                   </Row>
                  </Modal.Body>
                </Modal>

            </div>
        )
    }
    
}

export default TrustedModal;
